import { CHECK_AUTH, CHECK_AUTH_SUCCESSFUL, CHECK_LOGIN, LOGIN_USER_SUCCESSFUL, API_ERROR,LOGOUT_USER, LOGOUT_USER_SUCCESS } from './actionTypes';

export const checkAuth = () => {
    return {
        type: CHECK_AUTH,
        payload: {  }
    };
};

export const checkAuthSuccessful = payload => {
    return {
        type: CHECK_AUTH_SUCCESSFUL,
        payload
    };
};

export const checkLogin = (user, history) => {
    return {
        type: CHECK_LOGIN,
        payload: { user, history }
    };
};

export const loginUserSuccessful = payload => {
    return {
        type: LOGIN_USER_SUCCESSFUL,
        payload
    };
};

export const apiError = error => {
    return {
        type: API_ERROR,
        payload: error
    };
};

export const logoutUser = history => {
    return {
        type: LOGOUT_USER,
        payload: { history }
    };
};

export const logoutUserSuccess = () => {
    return {
        type: LOGOUT_USER_SUCCESS,
        payload: {}
    };
};
