import SimpleBar from 'simplebar-react';
import SidebarContent from './SidebarContent';

const Sidebar = ({ type, toggleMenu }) => {
    return (
        <div className="vertical-menu">
            <div data-simplebar className="h-100">
                {(type !== 'condensed')
                    ? (<SimpleBar style={{ maxHeight: "100%" }}><SidebarContent /></SimpleBar>)
                    : <SidebarContent toggleMenu={toggleMenu} />
                }
            </div>
        </div>
    );
};

export default Sidebar;