import { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeTopbarTheme,
    changeLayoutTheme,
    changeLayoutWidth
} from '../../store/actions';

// Layout Related Components
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import Rightbar from '../CommonForBoth/Rightbar';

const Layout = ({
    children,
    isPreloader,
    leftSideBarTheme,
    changeSidebarTheme,
    layoutWidth,
    changeLayoutWidth,
    leftSideBarType,
    changeSidebarType,
    topbarTheme,
    changeTopbarTheme,
    theme,
    changeLayoutTheme
}) => {
    const location = useLocation();

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const toggleMenu = () => {
        if (leftSideBarType === 'default') {
            changeSidebarType('condensed', isMobile);
        } else if (leftSideBarType === 'condensed') {
            changeSidebarType('default', isMobile);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        const capitalizeFirstLetter = s => s.charAt(1).toUpperCase() + s.slice(2);
        document.title = `${capitalizeFirstLetter(location.pathname).replaceAll('-', ' ')} | Car Rental`;

        if (leftSideBarTheme) {
            changeSidebarTheme(leftSideBarTheme);
        }

        if (layoutWidth) {
            changeLayoutWidth(layoutWidth);
        }

        if (leftSideBarType) {
            changeSidebarType(leftSideBarType);
        }
        if (topbarTheme) {
            changeTopbarTheme(topbarTheme);
        }
        if (theme) {
            changeLayoutTheme(theme);
        }

        if (isPreloader === true) {
            document.getElementById('preloader').style.display = 'block';
            document.getElementById('status').style.display = 'block';

            setTimeout(function () {
                document.getElementById('preloader').style.display = 'none';
                document.getElementById('status').style.display = 'none';
            }, 2500);
        } else {
            document.getElementById('preloader').style.display = 'none';
            document.getElementById('status').style.display = 'none';
        }
    }, [location, isPreloader, leftSideBarTheme, layoutWidth, leftSideBarType, topbarTheme, theme, changeSidebarTheme, changeLayoutWidth, changeSidebarType, changeTopbarTheme, changeLayoutTheme]);

    return (
        <Fragment>
            <div id="preloader">
                <div id="status">
                    <div className="spinner">
                        <i className="ri-loader-line spin-icon"></i>
                    </div>
                </div>
            </div>

            <div id="layout-wrapper">
                <Header toggleMenu={toggleMenu} />
                <Sidebar theme={leftSideBarTheme} type={leftSideBarType} toggleMenu={toggleMenu}/>
                <div className="main-content">
                    {children}
                    <Footer />
                </div>
            </div>
            <Rightbar />
        </Fragment>
    );
};

export default connect(s => ({ ...s.Layout }), {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeTopbarTheme,
    changeLayoutTheme,
    changeLayoutWidth
})(Layout);