import { Col, FormGroup, Label } from 'reactstrap';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { put } from '../../../helpers/api_helper';
import ValidatedInput from '../../Common/ValidatedInput';

const BrandSwitcher = ({ user, instances }) => {
    const navigate = useNavigate();

    const setBrand = async (id) => {
        const { success } = (await put(`instances/${id}`)).data;
        if (success) {
            navigate(0);
        }
    };

    return (
        <FormGroup row className="align-items-center text-end mt-3">
           {!user.level ?<Label xs={3} className="mb-0 me-2"><strong>Brand:</strong></Label> :''}
           {!user.level ?<Col xs={8}>
                <ValidatedInput className="px-2 py-1" name="brand" type="select" defaultValue={user.instanceId} floating={false} onChange={e => setBrand(e.target.value)}>
                    {instances.map(({ id, name }) => (
                        <option key={id} value={id}>{name}</option>
                    ))}
                </ValidatedInput>
            </Col> :''}
        </FormGroup>
    );
};

export default connect(({ Login: { user, instances } }) => ({ user, instances }), {})(BrandSwitcher);