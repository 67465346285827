import { Fragment } from 'react';
import { connect } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

const NonAuthLayout = ({ children, location, loading, user }) => {
    const loc = useLocation();

    const capitalizeFirstLetter = s => s.charAt(1).toUpperCase() + s.slice(2);

    document.title = `${capitalizeFirstLetter(loc.pathname).replaceAll('-', ' ')} | Car Rental`;

    return (user && !loading)
        ? (<Navigate to={{ pathname: '/dashboard', state: { from: location } }} />)
        : (<Fragment>{children}</Fragment>);
};

export default connect(({ Login: { loading, user } }) => ({ loading, user }))(NonAuthLayout);