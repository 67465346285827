import { Component, Fragment } from 'react';
import { Label, Input } from 'reactstrap';

import { connect } from 'react-redux';
import {
    hideRightSidebar,
    changeLayout,
    changeLayoutWidth,
    changeSidebarTheme,
    changeSidebarType, changePreloader,
    changeTopbarTheme,
    changeLayoutTheme
} from '../../store/actions';

//SimpleBar
import SimpleBar from 'simplebar-react';

import { Link, useNavigate } from 'react-router-dom';

import './rightbar.scss';
import { withTranslation } from 'react-i18next';
import { logoutUser } from '../../store/actions';

const withRouter = WrappedComponent => props => {
    const navigate = useNavigate();

    return (
        <WrappedComponent
            {...props}
            {...{ navigate }}
        />
    );
};

class RightSidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            layoutType: this.props.layoutType,
            sidebarType: this.props.leftSideBarType,
            layoutWidth: this.props.layoutWidth,
            sidebarTheme: this.props.leftSideBarTheme,
            topbarTheme: this.props.topbarTheme,
            theme: this.props.theme,
        };
        this.hideRightbar = this.hideRightbar.bind(this);
        this.changeLayout = this.changeLayout.bind(this);
        this.changeLayoutWidth = this.changeLayoutWidth.bind(this);
        this.changeLeftSidebarTheme = this.changeLeftSidebarTheme.bind(this);
        this.changeLeftSidebarType = this.changeLeftSidebarType.bind(this);
        this.changeTopbarTheme = this.changeTopbarTheme.bind(this);
        this.changeLayoutTheme = this.changeLayoutTheme.bind(this);
        this.changeThemePreloader = this.changeThemePreloader.bind(this);
        this.t = this.t.bind(this);
        this.logout = this.logout.bind(this);
    }

    /**
      * Hides the right sidebar
      */
    hideRightbar(e) {
        e.preventDefault();
        this.props.hideRightSidebar();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({
                layoutType: this.props.layoutType,
                sidebarType: this.props.leftSideBarType,
                layoutWidth: this.props.layoutWidth,
                sidebarTheme: this.props.leftSideBarTheme,
                topbarTheme: this.props.topbarTheme,
                theme: this.props.theme
            });
        }
    }

    changeThemePreloader = () => {
        this.props.changePreloader(!this.props.isPreloader);
    }
    /**
     * Change the layout
     * @param {*} e
     */
    changeLayout(e) {
        if (e.target.checked) {
            this.props.changeLayout(e.target.value);
        }
    }

    /**
     * Changes layout width
     * @param {*} e 
     */
    changeLayoutWidth(e) {
        if (e.target.checked) {
            this.props.changeLayoutWidth(e.target.value);
        }
    }

    // change left sidebar design
    changeLeftSidebarType(e) {
        if (e.target.checked) {
            this.props.changeSidebarType(e.target.value);
        }
    }

    // change left sidebar theme
    changeLeftSidebarTheme(e) {
        if (e.target.checked) {
            this.props.changeSidebarTheme(e.target.value);
        }
    }

    // change topbar theme
    changeTopbarTheme(e) {
        if (e.target.checked) {
            this.props.changeTopbarTheme(e.target.value);
        }
    }

    // change theme Mode
    changeLayoutTheme(e) {
        if (e.target.checked) {
            this.props.changeLayoutTheme(e.target.value);
        }
    }

    t(value) {
        return this.props.t(value);
    }

    logout(navigate) {
        this.props.logoutUser(navigate);
    }

    render() {
        return (
            <Fragment>
                <div className="right-bar">
                    <SimpleBar style={{ height: "900px" }}>
                        <div data-simplebar className="h-100">
                            <div className="rightbar-title px-3 py-4">
                                <Link to="#" onClick={this.hideRightbar} className="right-bar-toggle float-end">
                                    <i className="mdi mdi-close noti-icon"></i>
                                </Link>
                                <h5 className="m-0">Settings</h5>
                            </div>

                            <hr className="my-0" />

                            <div className="p-4">
                                <div className="radio-toolbar">
                                    <span className="mb-2 d-block" id="radio-title">Theme</span>
                                    <Input
                                        type="radio"
                                        id="radioThemeLightMode"
                                        name="radioThemeMode"
                                        value="light"
                                        checked={this.state.theme === 'light'}
                                        onClick={e => { this.changeLayoutTheme(e); this.changeLeftSidebarTheme(e) }}
                                        onChange={e => { this.changeLayoutTheme(e); this.changeLeftSidebarTheme(e) }} />
                                    <Label htmlFor="radioThemeLightMode">Light</Label>
                                    {"   "}
                                    <Input
                                        type="radio"
                                        id="radioThemeDarkMode"
                                        name="radioThemeMode"
                                        value="dark"
                                        checked={this.state.theme === 'dark'}
                                        onClick={e => { this.changeLayoutTheme(e); this.changeLeftSidebarTheme(e) }}
                                        onChange={e => { this.changeLayoutTheme(e); this.changeLeftSidebarTheme(e) }} />
                                    <Label htmlFor="radioThemeDarkMode">Dark</Label>
                                    {"   "}
                                </div>
                                <button className="btn header-item ps-0 d-xl-none" onClick={(e) => {this.logout(this.props.navigate); this.hideRightbar(e)}}><i className="ri-shut-down-line align-middle me-1 text-danger"></i>{this.t('Logout')}</button>

                            </div>
                        </div>
                    </SimpleBar>
                </div>
                <div className="rightbar-overlay" onClick={this.hideRightbar}></div>
            </Fragment>
        );
    }
}

const mapStatetoProps = state => {
    return { ...state.Layout };
};

export default connect(mapStatetoProps, {
    hideRightSidebar,
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeLayoutWidth,
    changeTopbarTheme,
    changeLayoutTheme,
    changePreloader,
    logoutUser
})(withTranslation()(withRouter(RightSidebar)));
