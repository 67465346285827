import { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logoutUser } from '../../../store/actions';
import { withTranslation } from 'react-i18next';

const ProfileMenu = ({ t, user, logoutUser }) => {
    const navigate = useNavigate();

    const [menu, setMenu] = useState(false);

    return (
        <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-none d-xl-inline-block user-dropdown">
            <DropdownToggle tag="button" className="btn header-item waves-effect" id="page-header-user-dropdown">
                <span className="ms-1 text-transform">{user?.username}</span>
                <i className="mdi mdi-chevron-down d-none ms-1 d-xl-inline-block"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
                <DropdownItem className="text-danger" onClick={() => logoutUser(navigate)}>
                    <i className="ri-shut-down-line align-middle me-1 text-danger"></i> {t('Logout')}
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
};

export default connect(({ Login: { user } }) => ({ user }), { logoutUser })(withTranslation()(ProfileMenu));