import { connect } from 'react-redux';
import { Navigate, useLocation, useNavigation, useParams } from 'react-router-dom';
import VerticalLayout from './VerticalLayout';
import HorizontalLayout from './HorizontalLayout';
import FallbackComponent from './Common/FallbackComponent';
import { useEffect, useState } from 'react';

const AuthLayout = ({ children, location, loading, user, layoutType }) => {
    const navigation = useNavigation();
    const [showLoading, setShowLoading] = useState(false)
    const currentLocation = useLocation()
    const params = useParams()
    const threshold = currentLocation.pathname === `/bookings/${params.id}/edit` ? 5000 : 500

    useEffect(() => {
        let timer;
        if (navigation.state === 'loading') {
            timer = setTimeout(() => setShowLoading(true), threshold);
        } else {
            setShowLoading(false);
        }
        return () => clearTimeout(timer);
    }, [navigation.state, threshold]);

    if (loading) {
        return (<div>Loading...</div>);
    }
    const Layout = (layoutType === 'horizontal') ? HorizontalLayout : VerticalLayout;
    return user
        ? (<Layout>{showLoading ? <FallbackComponent /> : children}</Layout>)
        : (<Navigate to={{ pathname: '/login', state: { from: location } }} />);
};

export default connect(({ Login: { loading, user }, Layout: { layoutType } }) => ({ loading, user, layoutType }))(AuthLayout);