import { CHECK_AUTH, CHECK_AUTH_SUCCESSFUL, CHECK_LOGIN, LOGIN_USER_SUCCESSFUL, API_ERROR, LOGOUT_USER, LOGOUT_USER_SUCCESS } from './actionTypes';

const initialState = {
    loginError: '', message: null, loading: true, user: null
};

const login = (state = initialState, action) => {
    switch (action.type) {
        case CHECK_AUTH:
            state = {
                ...state,
                loading: true
            };
            break;
        case CHECK_AUTH_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                user: action.payload?.user,
                instances: action.payload?.instances
            };
            break;
        case CHECK_LOGIN:
            state = {
                ...state,
                loading: true
            };
            break;
        case LOGIN_USER_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                user: action.payload?.user,
                instances: action.payload?.instances
            };
            break;

        case LOGOUT_USER:
            state = { ...state };
            break;

        case LOGOUT_USER_SUCCESS:
            state = {
                ...state,
                user: null
            };
            break;
            
        case API_ERROR:
            state = {
                ...state,
                loading: false,
                loginError: action.payload
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default login;