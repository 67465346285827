import axios from 'axios';
import { redirect } from 'react-router-dom';
import { ToastSuccess, ToastFail } from './alerts/swal';

export const axiosApi = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json'
    }
});

export async function get(url, config = {}) {
    return axiosApi.get(url, config);
}

export async function post(url, data = {}, config = {}) {
    return axiosApi.post(url, data, config);
}

export async function put(url, data = {}, config = {}) {
    return axiosApi.put(url, data, config);
}

export async function del(url, config = {}) {
    return axiosApi.delete(url, config);
}

export function generateAction(path, subPath = '') {
    return async ({ params: { id }, request }) => {
        const formData = await request.formData();
        try {
            const result = (await (id ? put(`${path}/${id}${subPath}`, formData) : post(path, formData))).data;
            ToastSuccess.fire();
            return result?.id ? redirect(`/${path}/${result.id}/${subPath || 'edit'}`) : result;
        } catch (e) {
            ToastFail.fire(e?.response?.data?.errors?.[0]?.msg ?? 'Something went wrong.');
            return e?.response?.data?.errors ?? 'Something went wrong.';
        }
    };
}