import { Navigate } from 'react-router-dom';

import AuthLayout from '../components/AuthLayout';
import NonAuthLayout from '../components/NonAuthLayout';

import Error500 from '../pages/Utility/Error500';

const createRouteOptions = (Layout, { path, component, lazy, loader, action }) => ({
	path,
	...(lazy
		? {
			lazy: async () => {
				const { Element, Component, loader, action, errorElement } = await lazy();
				const a = {
					loader,
					action,
					errorElement: errorElement ?? (<Error500 />)
				};
				a.element = Element ? (<Element />) : (Component ? (Layout ? (<Layout><Component /></Layout>) : <Component />) : undefined);
				return a;
			}
		}
		: {
			loader,
			action,
			element: Layout ? (<Layout>{component}</Layout>) : component
		}
	)
});

export const authProtectedRoutes = [
	{ path: '/dashboard', lazy: () => import('../pages/Dashboard/Dashboard') },

	// Bookings
	{ path: '/bookings', lazy: () => import('../pages/Bookings/index') },
	{ path: '/bookings/create', lazy: () => import('../pages/Bookings/create') },
	{ path: '/bookings/:id/edit', lazy: () => import('../pages/Bookings/edit') },
	{ path: '/bookings/:id/files', lazy: () => import('../pages/Bookings/files') },
	{ path: '/bookings/:id/invoice', lazy: () => import('../pages/Bookings/invoice') },
	{ path: '/bookings/activity', lazy: () => import('../pages/Bookings/activity') },
	{ path: '/bookings/activity/print', lazy: () => import('../pages/Bookings/Activity/print') },

	// Vehicles
	{ path: '/vehicles', lazy: () => import('../pages/Vehicles/index') },
	{ path: '/vehicles/create', lazy: () => import('../pages/Vehicles/edit') },
	{ path: '/vehicles/:id/edit', lazy: () => import('../pages/Vehicles/edit') },
	{ path: '/vehicles/models', lazy: () => import('../pages/Vehicles/Models/index') },
	{ path: '/vehicles/models/create', lazy: () => import('../pages/Vehicles/Models/edit') },
	{ path: '/vehicles/models/:id/edit', lazy: () => import('../pages/Vehicles/Models/edit') },
	{ path: '/vehicles/categories', lazy: () => import('../pages/Vehicles/Categories/index') },
	{ path: '/vehicles/categories/create', lazy: () => import('../pages/Vehicles/Categories/edit') },
	{ path: '/vehicles/categories/:id/edit', lazy: () => import('../pages/Vehicles/Categories/edit') },
	{ path: '/vehicles/calendar', lazy: () => import('../pages/Vehicles/calendar') },
	{ path: '/vehicles/dashboard', lazy: () => import('../pages/Vehicles/dashboard') },

	// Invoices
	{ path: '/invoices', lazy: () => import('../pages/Invoices/index') },
	{ path: '/invoices/create', lazy: () => import('../pages/Invoices/edit') },
	{ path: '/invoices/:id/edit', lazy: () => import('../pages/Invoices/edit') },

	// Human Resource
	{ path: '/users', lazy: () => import('../pages/Users/index') },
	{ path: '/users/create', lazy: () => import('../pages/Users/edit') },
	{ path: '/users/:id/edit', lazy: () => import('../pages/Users/edit') },

	// Settings
	{ path: '/settings', lazy: () => import('../pages/Settings/edit') },
	{ path: '/products', lazy: () => import('../pages/Products/index') },
	{ path: '/products/create', lazy: () => import('../pages/Products/edit') },
	{ path: '/products/:id/edit', lazy: () => import('../pages/Products/edit') },

	// Revenue
	{ path: '/revenue/reconciliation', lazy: () => import('../pages/Revenue/Reconciliation/index') },
	{ path: '/revenue/flags', lazy: () => import('../pages/Revenue/Flags/index') },

	// Reports
	{ path: '/reports/booking', lazy: () => import('../pages/Reports/booking') },
	{ path: '/reports/revenue', lazy: () => import('../pages/Reports/revenue') },
	{ path: '/reports/extensions', lazy: () => import('../pages/Reports/extensions') },
	{ path: '/reports/extras', lazy: () => import('../pages/Reports/extras') },
	{ path: '/reports/volume', lazy: () => import('../pages/Reports/volume') },
	{ path: '/reports/analytics', lazy: () => import('../pages/Analytics/index') },

	{ path: '/', exact: true, component: <Navigate to='/dashboard' /> },
].map(r => createRouteOptions(AuthLayout, r));

export const loginRoutes = [
	{ path: '/login', lazy: () => import('../pages/Authentication/Login') },
].map(r => createRouteOptions(NonAuthLayout, r));

export const publicRoutes = [
	{ path: '/bookings/:id/voucher', lazy: () => import('../pages/Bookings/voucher') },
    { path: '/invoices/:id/pay', lazy: () => import('../pages/Invoices/pay') },
    { path: '*', lazy: () => import('../pages/Utility/Error404') }
].map(r => createRouteOptions(null, r));