import axios from 'axios';
import { del, get, post, put } from "./api_helper";
import * as url from "./url_helper"

// Gets the logged in user data from local session 
export const getLoggedInUser = () => {
    const user = localStorage.getItem('authUser');
    return user ? JSON.parse(user) : null;
};

//is user is logged in
export const isUserAuthenticated = () => {
    return getLoggedInUser() !== null;
};

// Register Method
export const postRegister = async (url, data) => {
    try {
        return (await axios.post(url, data)).data;
    } catch (err) {
        throw new Error((err.response?.status === 401) ? 'Invalid credentials' : 'Sorry! Something went wrong, please contact our support team');
    }
};

export const checkLoginStatus = async (url, data) => {
    try {
        return (await get(url, data)).data;
    } catch (err) {
        throw new Error('Sorry! Something went wrong, please contact our support team');
    }
};

export const login = async (url, data) => {
    try {
        return (await post(url, data)).data;
    } catch (err) {
        throw new Error((err.response?.status === 401) ? 'Invalid credentials' : 'Sorry! Something went wrong, please contact our support team');
    }
};

export const logout = async (url, data) => {
    try {
        return (await del(url, data)).data;
    } catch (err) {
        throw new Error((err.response?.status === 401) ? 'Invalid credentials' : 'Sorry! Something went wrong, please contact our support team');
    }
};

export const postForgetPwd = (url, data) => {
    return axios.post(url, data).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response.data;
    }).catch(err => {
        throw err[1];
    });
};

// get Events
export const getEvents = () => get(url.GET_EVENTS);

// add Events
export const addNewEvent = event => post(url.ADD_NEW_EVENT, event);

// update Event
export const updateEvent = event => put(url.UPDATE_EVENT, event);

// delete Event
export const deleteEvent = event => del(url.DELETE_EVENT, {headers: {event}});

// get Categories
export const getCategories = () => get(url.GET_CATEGORIES);

// get Products
export const getProducts = () => get(url.GET_PRODUCTS);

// get Product detail
export const getProductDetail = id => get(`${url.GET_PRODUCTS_DETAIL}/${id}`, {params: {id}});

// get orders
export const getOrders = () => get(url.GET_ORDERS);

// add order
export const addNewOrder = order => post(url.ADD_NEW_ORDER, order);

// update order
export const updateOrder = order => put(url.UPDATE_ORDER, order);

// delete order
export const deleteOrder = order => del(url.DELETE_ORDER, {headers: {order}});

// get cart data
export const getCartData = () => get(url.GET_CART_DATA);

// get customers
export const getCustomers = () => get(url.GET_CUSTOMERS);

// get shops
export const getShops = () => get(url.GET_SHOPS);

// get chats
export const getChats = () => get(url.GET_CHATS);

// get groups
export const getGroups = () => get(url.GET_GROUPS);

// get Contacts
export const getContacts = () => get(url.GET_CONTACTS);

// get messages
export const getMessages = (roomId = "") => get(`${url.GET_MESSAGES}/${roomId}`, {params: {roomId}});

// post messages
export const addMessage = message => post(url.ADD_MESSAGE, message);
