import Swal from 'sweetalert2';

const Toast = Swal.mixin({
    position: 'top-end',
    toast: true,
    showConfirmButton: false,
    showCloseButton: true,
    timerProgressBar: true,
    timer: 10000,
    didOpen: toast => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
});

const ToastSuccess = Toast.mixin({
    icon: 'success',
    title: 'Changes Saved Successfully!'
});

const ToastFail = Toast.mixin({
    icon: 'error',
    title: 'Save Failed!'
});

export {
    Swal,
    Toast,
    ToastSuccess,
    ToastFail
};