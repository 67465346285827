import { useState } from 'react';
import { connect } from 'react-redux';
import {
    Form,
    FormGroup,
    InputGroup,
    Input,
    Button,
} from 'reactstrap';

import { Link } from 'react-router-dom';

import BrandSwitcher from '../CommonForBoth/TopbarDropdown/BrandSwitcher';
import LanguageDropdown from '../CommonForBoth/TopbarDropdown/LanguageDropdown';
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu';

//Import i18n
import { withTranslation } from 'react-i18next';

// Redux Store
import { toggleRightSidebar } from '../../store/actions';

//Import logo Images
import logosmdark from '../../assets/images/logo-sm-dark.png';
import logodark from '../../assets/images/logo-dark.png';
import logosmlight from '../../assets/images/logo-sm-light.png';
import logolight from '../../assets/images/logo-light.png';

const Header = ({ t, toggleMenu, toggleRightSidebar }) => {
    const [isSearch, setIsSearch] = useState(false);

    const toggleFullscreen = () => {
        if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement) {
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    };

    return (
        <header id="page-topbar">
            <div className="navbar-header">
                <div className="d-flex">
                    <div className="navbar-brand-box">
                        <Link to="#" className="logo logo-dark">
                            <span className="logo-sm">
                                <img src={logosmdark} alt="" height="20" />
                            </span>
                            <span className="logo-lg">
                                <img src={logodark} alt="" height="20" />
                            </span>
                        </Link>

                        <Link to="#" className="logo logo-light">
                            <span className="logo-sm">
                                <img src={logosmlight} alt="" height="20" />
                            </span>
                            <span className="logo-lg">
                                <img src={logolight} alt="" height="20" />
                            </span>
                        </Link>
                    </div>

                    <Button size="sm" color="none" type="button" onClick={toggleMenu} className="px-3 font-size-24 header-item waves-effect" id="vertical-menu-btn">
                        <i className="ri-menu-2-line align-middle"></i>
                    </Button>

                    <Form action="/bookings" className="app-search d-none d-lg-block">
                        <div className="position-relative">
                            <Input type="text" name="q" className="form-control" placeholder={t('Search')} />
                            <span className="ri-search-line"></span>
                        </div>
                    </Form>
                </div>

                <div className="d-flex">
                    <div className="dropdown d-inline-block d-lg-none ms-2">
                        <button type="button" onClick={() => setIsSearch(!isSearch)} className="btn header-item noti-icon waves-effect ps-0 pe-3" id="page-header-search-dropdown">
                            <i className="ri-search-line"></i>
                        </button>
                        <div className={isSearch ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show" : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"}
                            aria-labelledby="page-header-search-dropdown">

                            <Form action="/bookings" className="p-3 search__dropdown--form">
                                <FormGroup className="m-0">
                                    <InputGroup>
                                        <Input type="text" name="q" className="form-control" placeholder={t('Search')} />
                                        <div className="input-group-append">
                                            <Button color="primary" type="submit"><i className="ri-search-line"></i></Button>
                                        </div>
                                    </InputGroup>
                                </FormGroup>
                            </Form>
                            <div className="search-overlay" onClick={() => setIsSearch(false)}></div>
                        </div>
                    </div>

                    <BrandSwitcher />

                    <LanguageDropdown />

                    <ProfileMenu />

                    <div className="dropdown d-inline-block">
                        <Button color="none" onClick={toggleRightSidebar} type="button" className="header-item noti-icon right-bar-toggle waves-effect">
                            <i className="ri-settings-2-line"></i>
                        </Button>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default connect(s => ({ layoutType: s.Layout.layoutType }), { toggleRightSidebar })(withTranslation()(Header));