import { useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { connect } from 'react-redux';

import { authProtectedRoutes, loginRoutes, publicRoutes } from './routes';

import { checkAuth } from './store/actions';

import './assets/scss/theme.scss';

const router = createBrowserRouter(loginRoutes.concat(authProtectedRoutes).concat(publicRoutes));

const App = ({ checkAuth }) => {
    useEffect(() => {
        checkAuth();
    }, [checkAuth]);

    return (<RouterProvider router={router} />);
};

export default connect(s => ({ layout: s.Layout }), { checkAuth })(App);