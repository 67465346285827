import { Fragment, useEffect, useState } from 'react';
import { FormGroup, FormFeedback, FormText, Label, Input } from 'reactstrap';
import { useLoaderData, useActionData } from 'react-router-dom';
import moment from 'moment';

export default function ValidatedInput({ children, name, title, text, type = 'text', defaultValue, value, dataKey, floating = true, onChange = () => { }, ...props }) {
    const data = useLoaderData();
    const actionData = useActionData();
    const errors = (Array.isArray(actionData) ? actionData : []).reduce((r, { path, msg }) => {
        r[path] = msg;
        return r;
    }, {});
    const id = title ? `${title.replace(/\s+/g, '-').toLowerCase()}-input` : null;
    const Wrapper = title ? FormGroup : Fragment;
    if (!title || ['textarea', 'file'].includes(type)) {
        floating = false;
    }
    if (defaultValue === undefined) {
        defaultValue = dataKey ? data?.[dataKey]?.[name] : data?.[name];
        switch (type) {
            case 'date':
                defaultValue = defaultValue ? moment(defaultValue).format('YYYY-MM-DD') : '';
                break;

            case 'datetime-local':
                defaultValue = defaultValue ? moment(defaultValue).format('YYYY-MM-DDTHH:mm') : '';
                break;

            case 'file':
                defaultValue = undefined;
                break;

            default:
                break;
        }
    }
    const wrapperProps = floating ? { floating } : {};

    const [inputValue, setInputValue] = useState(defaultValue ?? value ?? '');
    const [togglePassword, setTogglePassword] = useState(true)

    useEffect(() => {
        setInputValue(defaultValue ?? value ?? '');
    }, [defaultValue, value]);

    return (
        <Wrapper {...wrapperProps}>
            {title && !floating && <Label htmlFor={id}>{title}</Label>}
            {type==='password' && inputValue && <button className='show__pass' type='button' onClick={()=>setTogglePassword(prevState=>!prevState)}>{togglePassword ? "Show" : "Hide"}</button>}
            <Input
                type={togglePassword ? type : 'text'}
                name={name}
                id={id}
                placeholder={floating ? title : null}
                value={inputValue}
                onChange={e => {
                    setInputValue(e.target.value);
                    onChange(e);
                }}
                invalid={!!errors[name]}
                {...props}
            >{children}</Input>
            {floating && <Label htmlFor={id}>{title}</Label>}
            <FormFeedback invalid={errors[name]}>{errors[name]}</FormFeedback>
            {text && <FormText>{text}</FormText>}
        </Wrapper>
    );
};