import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CHECK_AUTH, CHECK_LOGIN, LOGOUT_USER } from './actionTypes';
import { apiError, checkAuthSuccessful, loginUserSuccessful, logoutUserSuccess } from './actions';

// AUTH related methods
import { checkLoginStatus, login, logout } from '../../../helpers/backend_Helper';

//If user is login then dispatch redux action's are directly from here.
function* checkLogin() {
    try {
        const response = yield call(checkLoginStatus, '/auth/session');
        yield put(checkAuthSuccessful(response));
    } catch (error) {
        yield put(apiError(error.message));
    }
}

//If user is login then dispatch redux action's are directly from here.
function* loginUser({ payload: { user, history } }) {
    try {
        const response = yield call(login, '/auth/session', { username: user.username, password: user.password });
        yield put(loginUserSuccessful(response));
        history('/dashboard');
    } catch (error) {
        yield put(apiError(error.message));
    }
}

function* logoutUser({ payload: { history } }) {
    try {
        yield call(logout, '/auth/session');
        yield put(logoutUserSuccess());
        history('/login');
    } catch (error) {
        yield put(apiError(error.message));
    }
}

export function* watchCheckLogin() {
    yield takeEvery(CHECK_AUTH, checkLogin);
}

export function* watchUserLogin() {
    yield takeEvery(CHECK_LOGIN, loginUser);
}

export function* watchUserLogout() {
    yield takeEvery(LOGOUT_USER, logoutUser);
}

function* loginSaga() {
    yield all([
        fork(watchCheckLogin),
        fork(watchUserLogin),
        fork(watchUserLogout),
    ]);
}

export default loginSaga;