import { useRef } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { Link, useRouteError, useNavigate } from 'react-router-dom';
import { logoutUser } from '../../store/actions';

//Import images
import errorImg from '../../assets/images/error-img.png';

const Error500 = ({ logoutUser }) => {
    const error = useRouteError();
    const navigate = useNavigate();
    const logoutAttempted = useRef(false);
    if (error.response?.status === 401) {
        if (!logoutAttempted.current) {
            logoutAttempted.current = true;
            logoutUser(navigate);
        }
    }
    return (
        <div className="my-5 pt-5">
            <Container>
                <Row>
                    <Col lg={12}>
                        <div className="text-center my-5">
                            <h1 className="fw-bold text-error">5 <span className="error-text">0<img src={errorImg} alt="" className="error-img" /></span> 0</h1>
                            <h3 className="text-uppercase">Internal Server Error</h3>
                            <div className="mt-5 text-center">
                                <Link to="/" className="btn btn-primary waves-effect waves-light">Back to Dashboard</Link>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default connect(null, { logoutUser })(Error500);