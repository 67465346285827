import { Spinner } from 'reactstrap';

const FallbackComponent = () => {
    return (
        <div className="page-content fallback">
            <Spinner color="primary" type="grow">Loading...</Spinner>
            <Spinner color="primary" type="grow">Loading...</Spinner>
            <Spinner color="primary" type="grow">Loading...</Spinner>
        </div>
    );
}

export default FallbackComponent;