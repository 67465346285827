import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import * as moment from 'moment-timezone';
import * as serviceWorker from './serviceWorker';
import App from './App';

import store from './store';

moment.tz.setDefault('UTC');

createRoot(document.getElementById('root')).render(
    <Provider store={store}>
        <App/>
    </Provider>
);

serviceWorker.unregister();